import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, useForm } from 'react-hook-form';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { AddressPinIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { ENERGY_MASTERS_TENANT_ID } from 'clipsal-cortex-utils/src/constants/common-constants';
import { AUSTRALIAN_STATE_TO_TIMEZONE } from 'clipsal-cortex-utils/src/constants/timezone-states';

import { patch, post } from '../../../api/api-helpers';
import { ElectricalConfiguration, MeteringConfiguration, SiteData, SiteDataToSave } from '../../../api/api-site';
import { useAppDispatch } from '../../../app/hooks';
import { CustomButton } from '../../../common/components/CustomButton';
import { CustomDatePicker } from '../../../common/components/date-picker/CustomDatePicker';
import { METERING_CONFIGURATIONS, VOLTAGE_CONFIGURATIONS } from '../../../common/constants';
import { useSiteRouteChangeContext } from '../../../context/context-exports';
import { formatDate } from '../../../utils/datetime-utils';
import { selectUser } from '../../user/userSlice';
import { setHasUnsavedChanges } from '../../wizard/wizardSlice';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { checkMeterPhasing } from '../meter-setup/meter-phasing-helpers';
import { addMeters, selectRawMeters } from '../meter-setup/meterSetupSlice';
import { selectSite, setCurrentSite } from '../siteSlice';
import { ControlledLoadTutorialModal } from './ControlledLoadTutorialModal';
import { ManualAddressDialog, ManualAddressFormData } from './ManualAddressDialog';
import { SiteImage } from './SiteImage';
import { VoltageConfigurationTutorialModal } from './VoltageConfigurationTutorialModal';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_KEY as string;

type ErrorResponse = {
  code: number;
  detail: string;
  errors: Record<string, { code: string; message: string }[]>;
};

export type SiteDetailsFormData = {
  installationDate: string;
  name: string;
  address: string;
  meterConfiguration: string;
  voltageConfiguration: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  latitude: number;
  longitude: number;
  timezone: string;
  shouldHaveFlexibleExports: boolean | null;
  nmi: string | null;
};

const schema = yup.object().shape({
  installationDate: yup.string().required(),
  name: yup.string().required(),
  voltageConfiguration: yup.string().not(['0']).required(),
  meterConfiguration: yup.string().not(['0']).required(),
  address: yup.string().required('Site address is required.'),
  shouldHaveFlexibleExports: yup.boolean(),
  // eslint-disable-next-line max-len
  // NMI validation reference: https://aemo.com.au/-/media/files/electricity/nem/retail_and_metering/metering-procedures/nmi-allocation-list.pdf?la=en
  nmi: yup
    .string()
    .nullable()
    .when('shouldHaveFlexibleExports', {
      is: true,
      then: yup
        .string()
        .min(10, 'NMI must contain at least 10 characters or digits.')
        .max(11, 'NMI must not contain more than 11 characters or digits.')
        .matches(/^[^OI]*$/im, '"O" or "I" letters not allowed')
        .required('NMI is required'),
    }),
});

export function SiteDetails() {
  const { onMoveForward } = useSiteRouteChangeContext();
  const [shouldDisplayAutocompleteOptions, setShouldDisplayAutocompleteOptions] = useState(false);
  const {
    register,
    setValue,
    control,
    getValues,
    reset,
    setError,
    watch,
    handleSubmit: handleFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SiteDetailsFormData>({
    resolver: yupResolver(schema),
  });
  const [siteImageLink, setSiteImageLink] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const isNewSite = id === 'new';
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const rawMeters = useSelector(selectRawMeters);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isControlledLoadModalOpen,
    onOpen: onControlledLoadModalOpen,
    onClose: onControlledLoadModalClose,
  } = useDisclosure();
  const {
    isOpen: isManualAddressDialogOpen,
    onOpen: onOpenManualAddressDialog,
    onClose: onCloseManualAddressDialog,
  } = useDisclosure();
  const selectedPhaseValue = watch('voltageConfiguration') as ElectricalConfiguration;
  const dividerColor = useColorModeValue('#0000001A', 'dusk100.400');
  const voltageConfigRef = register(`voltageConfiguration`);

  const tenantSupportsFlexibleExports = user.role === 'SUPER_ADMIN' || user.tenant_id === ENERGY_MASTERS_TENANT_ID;
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: GOOGLE_MAPS_API_KEY,
    options: {
      types: [],
      componentRestrictions: { country: ['AU', 'US', 'NZ'] },
    } as any,
  });

  useEffect(() => {
    // Annoying hack because chakra-ui automatically sets input `autocomplete` attribute to "new-password", even if
    // manually set to "off". Might be worth submitting a PR since this is fairly annoying.
    if (control._fields?.address?._f?.ref) {
      const ref = control._fields.address?._f?.ref as HTMLInputElement;
      ref.autocomplete = 'off';
    }
  }, [control._fields]);

  useEffect(() => {
    async function setSiteData() {
      if (id !== 'new' && site.isLoaded) {
        dispatch(setHasUnsavedChanges(false));

        reset({
          installationDate: site.install_date?.split(' ')[0],
          name: site.site_name,
          address: site.site_address_line1,
          state: site.site_state,
          postCode: site.site_zipcode,
          country: site.site_country,
          timezone: site.timezone,
          latitude: Number(site.site_latitude),
          longitude: Number(site.site_longitude),
          voltageConfiguration: site.electrical_config,
          meterConfiguration: site.metering_configuration,
          shouldHaveFlexibleExports: site.should_have_flexible_exports,
          nmi: site.nmi,
        });
      }
    }

    setSiteData();
  }, [id, dispatch, site, reset]);

  async function handleSubmit(data: SiteDetailsFormData) {
    // Validate auto-populated site details, e.g. postcode/timezone/state etc. These are required to get Cortex working
    // correctly for the user.
    if (!data.timezone || !data.postCode || !data.country || !data.state) {
      toast({
        title: 'Invalid site address',
        description:
          'Please make sure the site address is either added from the auto complete options, or set manually.',
        status: 'error',
        isClosable: true,
      });
      setError('address', {
        message: 'Please either select from the options below, or click "Enter address manually".',
      });
      return;
    }

    const siteData: SiteDataToSave = {
      install_date: formatDate(new Date(data.installationDate)) + ' 00:00:00',
      site_name: data.name,
      site_city: data.city,
      site_state: data.state,
      site_zipcode: data.postCode,
      site_country: data.country,
      site_address_line1: data.address,
      timezone: data.timezone,
      street_address: data.address.split(',')?.[0],
      metering_configuration: data.meterConfiguration as MeteringConfiguration,
      electrical_config: data.voltageConfiguration as ElectricalConfiguration,
      site_latitude: null,
      site_longitude: null,
      nmi: data.nmi,
      should_have_flexible_exports: data.shouldHaveFlexibleExports,
    };

    if (data.latitude && data.longitude) {
      siteData.site_latitude = data.latitude.toString();
      siteData.site_longitude = data.longitude.toString();
    }

    try {
      if (isNewSite) {
        const site = await post<SiteData>(`/fleet/sites`, siteData);
        dispatch(setHasUnsavedChanges(false));
        dispatch(setCurrentSite(site));
        onMoveForward(site.clipsal_solar_id);
      } else {
        const site = await patch<SiteData>(`/fleet/sites/${id}`, { ...siteData, clipsal_solar_id: Number(id) });
        dispatch(setHasUnsavedChanges(false));
        // update phasing in meters if required as it needs to match site phasing
        const meterDevices = Object.values(rawMeters);
        if (meterDevices.length) {
          const { isCorrectlyPhased, updatedMeters } = checkMeterPhasing(
            data.voltageConfiguration as ElectricalConfiguration,
            meterDevices
          );
          if (!isCorrectlyPhased) {
            // Update each meter respectively
            const meters = await Promise.all(
              updatedMeters.map<Promise<WattwatchersMeter>>((m) =>
                patch(`/fleet/wattwatchers/installed_meters/${m.installed_device_id}`, m)
              )
            );
            toast({
              title: `Meter voltage references updated!`,
              description: 'Circuit voltage references has been updated as per site electrical configuration.',
              status: 'info',
              isClosable: true,
            });
            dispatch(addMeters(meters));
          }
        }
        dispatch(setCurrentSite(site));
        onMoveForward(site.clipsal_solar_id);
      }
    } catch (e) {
      const error = e as AxiosError;

      // Show NMI errors surfaced from BE to the user
      // Only show 400 errors and when error response has an NMI error
      if (error.response?.status === 400 && error.response.data) {
        const data = error.response.data as ErrorResponse;
        const nmiErrorMessage = data.errors?.nmi?.[0]?.message;
        if (nmiErrorMessage) setError('nmi', { message: nmiErrorMessage });
      }

      console.error(e);

      toast({
        title: 'Unable to save this site.',
        description: 'Please try again. If this persists, contact an administrator.',
        status: 'error',
        isClosable: true,
      });
    }
  }

  async function handleSelectLocation(location: AutocompletePrediction) {
    const currentValues = getValues();

    placesService?.getDetails({ placeId: location.place_id }, async (details) => {
      const locationData: Record<string, string | undefined> = {};

      // iterate over address components to populate location data
      details?.address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          locationData.city = component?.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          locationData.state = component?.short_name;
        } else if (component.types.includes('country')) {
          locationData.country = component?.long_name;
        } else if (component.types.includes('postal_code')) {
          locationData.postCode = component?.long_name;
        }
      });

      const latitude = details?.geometry?.location?.lat();
      const longitude = details?.geometry?.location?.lng();

      const {
        data: { timeZoneId: timezone },
      } = await axios.get(
        'https://maps.googleapis.com/maps/api/timezone/json?location=' +
          latitude +
          ',' +
          longitude +
          '&timestamp=' +
          Math.floor(Date.now() / 1000) +
          '&language=es&key=' +
          GOOGLE_MAPS_API_KEY
      );

      const termsLength = location.terms.length;

      reset({
        ...currentValues,
        address: location.description,
        city: locationData.city || location.terms[termsLength - 3]?.value,
        state: locationData.state || location.terms[termsLength - 2]?.value,
        country: locationData.country || location.terms[termsLength - 1]?.value,
        timezone,
        latitude,
        longitude,
        postCode: locationData.postCode,
      });

      setShouldDisplayAutocompleteOptions(false);
      setSiteImageLink(
        'https://maps.googleapis.com/maps/api/staticmap' +
          '?sensor=false' +
          '&size=640x400' +
          '&maptype=satellite' +
          `&center=${latitude},${longitude}` +
          `&key=${GOOGLE_MAPS_API_KEY}` +
          '&zoom=20'
      );
    });
  }

  async function handleChangeVoltageConfig(e: ChangeEvent<HTMLSelectElement>) {
    const value = e.currentTarget.value;
    await voltageConfigRef.onChange(e);

    // Check local storage to see if the voltage config type popup has been disabled
    // If not, display it.
    if (localStorage.getItem(`voltageConfigModalDisabled_${value}`) !== 'true') onOpen();
  }

  function handleSubmitManualAddress({ country, streetAddress, unit, suburb, state, postcode }: ManualAddressFormData) {
    // @TODO: when we move to US, look at timezones for US -- some states have multiple timezones
    const timezone = {
      Australia: AUSTRALIAN_STATE_TO_TIMEZONE[state],
      'New Zealand': 'Pacific/Auckland',
      'United States': 'America/Los_Angeles',
    }[country];

    reset({
      ...getValues(),
      address: `${unit ? unit + '/' : ''}${streetAddress}, ${suburb}, ${state}`,
      city: suburb,
      state,
      country,
      timezone,
      postCode: postcode.toString(),
    });

    onCloseManualAddressDialog();
    setShouldDisplayAutocompleteOptions(false);
  }

  async function handleToggleFlexibleExports(e: ChangeEvent<HTMLInputElement>) {
    const currentValues = getValues();
    reset({
      ...currentValues,
      shouldHaveFlexibleExports: e.target.checked,
      nmi: e.target.checked ? currentValues.nmi : '',
    });
  }

  function PredictionsList() {
    return placePredictions.length ? (
      <Box width={{ base: '100%', lg: '50%' }} mt={2} py={3}>
        <Heading mb={1} size={'md'}>
          Results
        </Heading>
        {placePredictions.map((location, i) => (
          <Box
            data-testid={`site-address-prediction-${i}`}
            className={'site-address-prediction-list'}
            borderBottom={i === placePredictions.length - 1 ? '1px solid grey' : undefined}
            borderTop={'1px solid grey'}
            key={`google-maps-location-${i}`}
            _hover={{ background: 'gray.50' }}
            onClick={async () => handleSelectLocation(location)}
            cursor={'pointer'}
            py={2}
          >
            <Flex align={'center'} as={'button'} type={'button'}>
              <Box mr={1}>
                <FaMapMarkerAlt />
              </Box>
              <Text fontWeight={600} fontSize={'sm'}>
                {location.description}
              </Text>
            </Flex>
          </Box>
        ))}
        <Text mt={2} fontSize={'xs'}>
          Powered by Google
        </Text>
      </Box>
    ) : null;
  }

  return (
    <>
      <WizardSubRouteCardWrapper p={[0]}>
        <Box>
          <Heading my={3} ml={[4, 6]} size={'lg'}>
            Site Details
          </Heading>
          <Divider borderColor={dividerColor} opacity={1} />
          <Box
            data-testid="site-details-form"
            onChange={() => dispatch(setHasUnsavedChanges(true))}
            onSubmit={handleFormSubmit(handleSubmit)}
            as={'form'}
            px={[4, 6]}
          >
            <FormControl my={3} isInvalid={!!errors.installationDate} id="name">
              <FormLabel>Installation date</FormLabel>
              <Controller
                control={control}
                name="installationDate"
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    maxDate={new Date()}
                    inputProps={{
                      'data-testid': `system-installation-date`,
                      placeholder: 'dd/mm/yyyy',
                    }}
                    popperPlacement="top-start"
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => date && onChange(formatDate(date))}
                    selected={value ? new Date(value) : null}
                  />
                )}
              />
              <FormErrorMessage>Installation date is required.</FormErrorMessage>
            </FormControl>

            <FormControl my={3} isInvalid={!!errors.name} id="name">
              <FormLabel>Site name</FormLabel>
              <Input
                data-private
                data-testid="site-name"
                placeholder={'John Smith Site'}
                {...register('name')}
                type="text"
              />
              <FormErrorMessage>Site name is required.</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.address} id="address">
              <FormLabel>Site address</FormLabel>
              <InputGroup>
                <Input
                  {...register('address')}
                  onChange={(e) => {
                    getPlacePredictions({ input: e.currentTarget.value });
                    setValue('address', e.currentTarget.value);
                    setShouldDisplayAutocompleteOptions(true);
                  }}
                  placeholder={'12/22 George Street, Sydney, NSW'}
                  type="text"
                  data-private
                  autoComplete="off"
                  data-testid="site-address"
                />
                <InputRightElement>
                  <AddressPinIcon color="customBlue.500" />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.address?.message}</FormErrorMessage>
            </FormControl>

            <Box
              onClick={onOpenManualAddressDialog}
              as="button"
              type="button"
              mt={1}
              fontSize="md"
              color="customBlue.500"
              data-testid="manual-address-button"
            >
              Enter address manually
            </Box>

            {/* Site address autocomplete */}
            <Box w={'100%'} data-private>
              {!isPlacePredictionsLoading ? (
                shouldDisplayAutocompleteOptions && <PredictionsList />
              ) : (
                <Flex direction={'column'} justify={'center'} align={'center'}>
                  <Spinner size={'lg'} />
                  <Text fontSize={'sm'}>Loading suggestions...</Text>
                </Flex>
              )}
            </Box>

            <FormControl mt={3} hidden={!tenantSupportsFlexibleExports}>
              <FormLabel>Flexible Exports</FormLabel>
              <Flex align="center">
                <Text mr={2}>Is this site being configured for Flexible Exports?</Text>
                <Switch
                  {...register('shouldHaveFlexibleExports')}
                  size="md"
                  data-testid="site-has-flexible-exports"
                  onChange={handleToggleFlexibleExports}
                  colorScheme={'primaryBranding'}
                  id="site-has-flexible-exports"
                />
              </Flex>
            </FormControl>

            <FormControl
              my={3}
              isInvalid={!!errors.nmi}
              id="customerNmi"
              hidden={!tenantSupportsFlexibleExports || !getValues().shouldHaveFlexibleExports}
            >
              <FormLabel>Customer NMI</FormLabel>
              <Input
                data-private
                data-testid="customer-nmi"
                {...register('nmi')}
                placeholder={'Enter NMI'}
                type="text"
              />
              <FormHelperText fontSize={'xs'}>
                Note: NMI must be between 10 and 11 characters or digits, and cannot contain the letters "O" or "I".
              </FormHelperText>
              <FormErrorMessage data-testid="nmi-error-message">{errors?.nmi?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.voltageConfiguration} mt={3} id={'voltageConfiguration'}>
              <FormLabel>Voltage type</FormLabel>
              <Select
                data-testid="site-voltage-configuration"
                name={voltageConfigRef.name}
                onBlur={voltageConfigRef.onBlur}
                ref={voltageConfigRef.ref}
                onChange={handleChangeVoltageConfig}
              >
                <option value={0}>Select a voltage type...</option>
                {VOLTAGE_CONFIGURATIONS.map(({ label, value }, i) => (
                  <option key={`voltage-config-option-${i}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>Voltage configuration is required.</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.meterConfiguration} mt={3} id={'meterConfiguration'}>
              <FormLabel>Metering configuration</FormLabel>
              <Select data-testid="site-meter-configuration" defaultValue={'NET'} {...register('meterConfiguration')}>
                <option value={0}>Select a metering configuration...</option>
                {METERING_CONFIGURATIONS.map(({ label, value }, i) => (
                  <option key={`metering-config-option-${i}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>Metering configuration is required.</FormErrorMessage>
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>Controlled load</FormLabel>
              <Flex align="center">
                <Text mr={2}>Is there a controlled load on site?</Text>
                <Switch
                  size="md"
                  data-testid="site-has-controlled-load"
                  onChange={(e) => {
                    const dontShowControlledLoadModal = localStorage.getItem('dontShowControlledLoadModal');
                    dontShowControlledLoadModal !== 'true' && e.target.checked && onControlledLoadModalOpen();
                  }}
                  colorScheme={'primaryBranding'}
                  id="site-has-controlled-load"
                />
              </Flex>
            </FormControl>

            {isControlledLoadModalOpen && (
              <ControlledLoadTutorialModal isOpen={isControlledLoadModalOpen} onClose={onControlledLoadModalClose} />
            )}

            <SiteImage siteImageUrl={siteImageLink} />

            <Divider my={5} color={dividerColor} />

            <CustomButton isLoading={isSubmitting} data-testid="site-details-form-submit">
              Next: System Details
            </CustomButton>
          </Box>
        </Box>
      </WizardSubRouteCardWrapper>

      {isManualAddressDialogOpen && (
        <ManualAddressDialog
          currentValues={{ country: getValues().country, address: getValues().address, postCode: getValues().postCode }}
          isOpen={isManualAddressDialogOpen}
          onClose={onCloseManualAddressDialog}
          onSubmitManualAddress={handleSubmitManualAddress}
        />
      )}

      {isOpen && (
        <VoltageConfigurationTutorialModal isOpen={isOpen} onClose={onClose} selectedPhaseValue={selectedPhaseValue} />
      )}
    </>
  );
}
