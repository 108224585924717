import React, { ReactElement, RefObject } from 'react';
import { Navigate } from 'react-router-dom';

import { RouteNotFound } from './common/components/route/RouteNotFound';
import { Login } from './features/authentication/login/Login';
import { Logout } from './features/authentication/Logout';
import { AccountActivationInfo, OTPVerification } from './features/authentication/signup/OTPVerification';
import { Signup } from './features/authentication/signup/Signup';
import { SignupForm } from './features/authentication/signup/SignupForm';
import { Dashboard } from './features/dashboard/Dashboard';
import { FleetDashboard } from './features/fleet-dashboard/FleetDashboard';
import { CustomerDetails } from './features/site/customer-details/CustomerDetails';
import { CTConfiguration } from './features/site/meter-setup/ct-configuration/CTConfigurationContainer';
import { MeterSetupConfiguration } from './features/site/meter-setup/meter-configuration/MeterSetupConfiguration';
import { MeterSetup } from './features/site/meter-setup/MeterSetup';
import { MeterSetupForm } from './features/site/meter-setup/MeterSetupForm';
import { MeterSetupPollingWrapper } from './features/site/meter-setup/MeterSetupPollingWrapper';
import { SwitchConfigurationContainer } from './features/site/meter-setup/switch-configuration/SwitchConfigurationContainer';
import { WifiConfiguration } from './features/site/meter-setup/wifi-configuration/WifiConfiguration';
import { Site } from './features/site/Site';
import { SiteDetails } from './features/site/site-details/SiteDetails';
import { SystemDetails } from './features/site/system-details/SystemDetails';
import { BatteryUpsell } from './features/upsell/BatteryUpsell';
import { BatteryUpsellDashboard } from './features/upsell/BatteryUpsellDashboard';
import { BatteryUpsellSimulations } from './features/upsell/BatteryUpsellSimulations';
import { Account } from './features/user/account/Account';
import { AccountAbout } from './features/user/account/AccountAbout';
import { AccountHome } from './features/user/account/AccountHome';
import { AccountSettings } from './features/user/account/AccountSettings';
import { BusinessDetails } from './features/user/account/BusinessDetails';
import { DataCorrection } from './features/user/account/data-correction/DataCorrection';
import { ManageUsers } from './features/user/account/manage-users/ManageUsers';
import { MeterConfiguration } from './features/user/account/meter-configuration/MeterConfiguration';

export interface Route {
  path: string;
  element: ReactElement;
  isPublic?: boolean;
  children?: Route[];
  nodeRef?: RefObject<HTMLDivElement>;
}

// eslint-disable-next-line react-refresh/only-export-components
export const routes: Route[] = [
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
    isPublic: true,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/login',
    element: <Login />,
    isPublic: true,
  },
  {
    path: '/signup',
    element: <Signup />,
    isPublic: true,
    children: [
      {
        path: '',
        element: <Navigate to="user-details" />,
        isPublic: true,
      },
      {
        path: 'user-details',
        element: <SignupForm />,
        isPublic: true,
      },
      {
        path: 'otp-verify',
        element: <OTPVerification />,
        isPublic: true,
      },
      {
        path: 'account-activation-info',
        element: <AccountActivationInfo />,
        isPublic: true,
      },
    ],
  },
  {
    path: '/logout',
    element: <Logout />,
    isPublic: true,
  },
  {
    path: '/site/:id',
    element: <Site />,
    children: [
      {
        path: 'start',
        element: <SiteDetails />,
      },
      {
        path: 'customer_details',
        element: <CustomerDetails />,
      },
      {
        path: 'system_details',
        element: <SystemDetails />,
      },
      {
        path: 'meter_setup',
        element: <MeterSetup />,
        children: [
          {
            path: 'meters',
            element: <MeterSetupForm />,
          },
          {
            path: 'configure',
            element: <MeterSetupPollingWrapper />,
            children: [
              {
                path: 'ct_configuration',
                element: <CTConfiguration />,
              },
              {
                path: 'wifi_configuration',
                element: <WifiConfiguration />,
              },
              {
                path: 'switch_configuration',
                element: <SwitchConfigurationContainer />,
              },
              {
                path: 'meter_configuration',
                element: <MeterSetupConfiguration />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/account',
    element: <Account />,
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: <AccountHome />,
      },
      {
        path: 'settings',
        element: <AccountSettings />,
      },
      {
        path: 'about',
        element: <AccountAbout />,
      },
      {
        path: 'manage_users',
        element: <ManageUsers />,
      },
      {
        path: 'business_details',
        element: <BusinessDetails />,
      },
      {
        path: '/account/meter_configuration',
        element: <MeterConfiguration />,
      },
      {
        path: '/account/data_correction',
        element: <DataCorrection />,
      },
    ],
  },
  {
    path: '/fleet_dashboard',
    element: <FleetDashboard />,
  },
  {
    path: '/upsells',
    element: <BatteryUpsell />,
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: <BatteryUpsellDashboard />,
      },
      {
        path: 'site/:id/simulations',
        element: <BatteryUpsellSimulations />,
      },
    ],
  },
  {
    path: '*',
    element: <RouteNotFound />,
  },
];
