import { useSelector } from 'react-redux';

import { InstalledDevice } from '../../../api/api-device';
import { baseApi } from '../../../app/baseApi';
import { selectSite } from '../siteSlice';

export const siteApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSiteMeters: build.query<InstalledDevice[], number>({
      query: (siteId) => `/fleet/sites/${siteId}/meters`,
      providesTags: (_, __, siteId) => [{ type: 'SiteMeters', siteId }],
    }),
    refreshSiteCircuits: build.mutation<void, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/refresh_circuits`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useRefreshSiteCircuitsMutation, useGetSiteMetersQuery } = siteApi;

export const useSiteMeters = () => {
  const { clipsal_solar_id: siteId } = useSelector(selectSite);
  const response = useGetSiteMetersQuery(siteId);

  return {
    siteMeters: response.data ?? [],
    isLoadingSiteMeters: response.isLoading,
    ...response,
  };
};
