import React, { useEffect, useMemo, useState } from 'react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Select, Skeleton, Spinner, Text, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { GenericError } from '../../../../common/types/types';
import { selectInverters } from '../../system-details/systemDetailsSlice';
import {
  useCheckConnectionStatusMutation,
  useCreateSiteDeviceConnectionMutation,
  useDeleteSiteDeviceConnectionMutation,
  useGetSiteDeviceConnectionsQuery,
} from './siteDeviceConnectionApi';

const SUPPORTED_CONNECTION_TYPE = 'MODBUS_RTU'; // TODO: Add MODBUS_TCP when supported

type Props = Readonly<{
  meter: WattwatchersMeter;
}>;

export function ModbusInfo(props: Props) {
  const { meter } = props;
  const inverters = useSelector(selectInverters);
  const [pollingInterval, setPollingInterval] = useState<number | undefined>(undefined);
  const { data: siteDeviceConnection, isLoading } = useGetSiteDeviceConnectionsQuery(
    Number(meter.installed_device_id),
    {
      pollingInterval,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data?.find((connection) => connection.connection_type === SUPPORTED_CONNECTION_TYPE),
      }),
    }
  );
  const [createSiteDeviceConnection] = useCreateSiteDeviceConnectionMutation();
  const [deleteSiteDeviceConnection] = useDeleteSiteDeviceConnectionMutation();
  const [checkConnectionStatus] = useCheckConnectionStatusMutation();
  const toast = useToast();

  const POLLING_INTERVAL_MODBUS_STATUS = 5_000;
  useEffect(() => {
    if (siteDeviceConnection?.connection_status === 'POLLING') {
      setPollingInterval(POLLING_INTERVAL_MODBUS_STATUS);
    } else {
      setPollingInterval(undefined);
    }
  }, [siteDeviceConnection]);

  async function handleChangeModbusConnection(option: React.ChangeEvent<HTMLSelectElement>) {
    try {
      const selectedChildId = option.currentTarget.value ? Number(option.currentTarget.value) : null;

      // We only delete the connection if it is deselected
      if (siteDeviceConnection && selectedChildId === null) {
        await deleteSiteDeviceConnection(siteDeviceConnection.id).unwrap();
      }
      if (selectedChildId) {
        await createSiteDeviceConnection({
          siteDeviceId: Number(meter.installed_device_id),
          body: { child_id: selectedChildId, connection_type: SUPPORTED_CONNECTION_TYPE },
        }).unwrap();
      }
    } catch (e) {
      const errorMessage = (e as GenericError)?.response?.data.message ?? 'Something went wrong, please try again.';
      toast({
        title: 'Error updating modbus connection',
        description: errorMessage,
        status: 'error',
        isClosable: true,
      });
    }
  }

  async function handleStartPollingModbusStatus() {
    try {
      await checkConnectionStatus(siteDeviceConnection!.id).unwrap();
    } catch (e) {
      const errorMessage = (e as GenericError)?.response?.data.message ?? 'Something went wrong, please try again.';
      toast({
        title: 'Error checking modbus connection status',
        description: errorMessage,
        status: 'error',
        isClosable: true,
      });
    }
  }

  const statusIcon = useMemo(() => {
    const { connection_status: connectionStatus } = siteDeviceConnection ?? {};

    if (connectionStatus === 'ACTIVE')
      return <CheckCircleIcon ml={2} color={'primaryBranding.500'} w={5} h={5} data-testid="active-icon" />;

    if (connectionStatus === 'POLLING')
      return <Spinner ml={2} color="primaryBranding.500" w={5} h={5} data-testid="spinner-icon" />;

    if (connectionStatus === 'TIMED_OUT' || connectionStatus === 'CONFIGURED')
      return <WarningIcon ml={2} color={'red.500'} w={5} h={5} data-testid="warning-icon" />;

    return null;
  }, [siteDeviceConnection]);

  return (
    <Box data-testid="modbus-info" my={2}>
      <Text mb={1} size={'md'}>
        Has Modbus Connection?
      </Text>
      <Skeleton isLoaded={!isLoading}>
        <Flex wrap={'wrap'} columnGap={2} justify={'space-between'} align={'center'}>
          <Flex minW="fit-content">
            <Select
              placeholder="Select child"
              defaultValue={siteDeviceConnection?.child_id}
              onChange={handleChangeModbusConnection}
              data-testid="modbus-connection-select"
            >
              {inverters.map((inverter) => (
                <option
                  key={inverter.row_id}
                  value={inverter.row_id}
                >{`${inverter.meta_data.manufacturer.manufacturer_name} ${inverter.meta_data.model}`}</option>
              ))}
            </Select>
          </Flex>
          <Flex minW="fit-content">
            <Button
              onClick={handleStartPollingModbusStatus}
              my={1}
              isDisabled={
                !siteDeviceConnection ||
                siteDeviceConnection?.connection_status === 'ACTIVE' ||
                siteDeviceConnection?.connection_status === 'POLLING'
              }
              data-testid="modbus-status-button"
            >
              Modbus Status {statusIcon}
            </Button>
          </Flex>
        </Flex>
      </Skeleton>
    </Box>
  );
}
