import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { get } from '../../api/api-helpers';
import { ElectricalConfiguration, MeteringConfiguration, SiteData, SiteStatus } from '../../api/api-site';
import { OperatingStatus } from '../../api/api-site-alerts';
import { RootState } from '../../app/store';

export type SiteState = SiteData & { isLoaded: boolean };

export const fetchSite = createAsyncThunk<SiteData, number, { state: RootState }>(
  'site/fetchSite',
  async (siteID) => await get<SiteData>(`/fleet/sites/${siteID}`)
);

const initialState: SiteState = {
  clipsal_solar_id: 0,
  distributor_id: 0,
  electrical_config: '' as ElectricalConfiguration,
  metering_configuration: '' as MeteringConfiguration,
  roof_type: '',
  retailer_id: 0,
  site_name: '',
  site_city: '',
  site_country: '',
  site_state: '',
  site_zipcode: '',
  site_latitude: '',
  site_longitude: '',
  site_address_line1: '',
  street_address: '',
  timezone: '',
  users: [],
  site_address_line2: null,
  site_address_line3: '',
  flagged: null,
  is_consumption_only: false,
  has_pulse: false,
  has_solar: false,
  install_date: '',
  has_battery: false,
  solar_capacity_kw: 0,
  commissioning_status: '' as SiteStatus,
  operating_status: 'normal' as OperatingStatus,
  nmi: null,
  should_have_flexible_exports: null,
  csip_commissioning_status: null,
  isLoaded: false,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setCurrentSite: (_state, action: PayloadAction<SiteData>) => {
      return { ...action.payload, isLoaded: true };
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSite.fulfilled, (_state, action) => {
      return { ...action.payload, isLoaded: true };
    });
  },
});

export const { setCurrentSite, reset } = siteSlice.actions;

export const selectSite = (state: RootState) => {
  return state.site;
};

export const { reducer: siteReducer } = siteSlice;
